import React, { useMemo } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  TableHead,
  Toolbar,
  Typography,
  IconButton
} from "@material-ui/core";
import { TimeSheetForm, TimeSheetOptions } from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import { FieldArray, FormikProps } from "formik";
import AfterregistrationTableRow from "./AfterregistrationTableRow";
import NoteAddIcon from "@material-ui/icons/NoteAdd";

const useStyles = makeStyles((theme) => ({
  addRowCell: {
    display: "flex",
    border: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingBottom: theme.spacing(0)
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  title: {
    flex: "0 0 auto"
  },
  tableContainer: {
    height: "100%",
    overflowY: "auto"
  },
  table: {
    minWidth: 750
  },
  checkbox: {
    color: "#e4cd00"
  }
}));

const headRows = (t: (translation: string) => string) =>
  [
    {
      id: "task",
      numeric: false,
      disablePadding: true,
      label: t("taskLabel")
    },
    {
      id: "servicecategory",
      numeric: false,
      disablePadding: false,
      label: t("serviceCategoryLabel")
    },
    {
      id: "accessories",
      numeric: false,
      disablePadding: false,
      label: t("accessoriesHeading")
    },
    {
      id: "start_time",
      numeric: true,
      disablePadding: true,
      label: t("timeHeading")
    },
    {
      id: "materials",
      numeric: false,
      disablePadding: false,
      label: t("materialsHeading")
    },
    {
      id: "notes",
      numeric: false,
      disablePadding: false,
      label: t("notesHeading")
    },
    {
      id: "buttons",
      numeric: false,
      disablePadding: true,
      label: ""
    }
  ] as const;

interface EnhancedTableHeadProps {
  hideAccessories: boolean;
  hideMaterials: boolean;
}

const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = ({
  hideAccessories,
  hideMaterials
}) => {
  const t = useTranslate("AfterregistrationPage");

  return (
    <TableHead>
      <TableRow>
        {headRows(t)
          .filter(
            (t) =>
              (!hideAccessories || t.id !== "accessories") &&
              (!hideMaterials || t.id !== "materials")
          )
          .map((row) => (
            <TableCell key={row.id} align="left">
              {row.label}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

interface AfterregistrationTableProps {
  formikProps: FormikProps<TimeSheetForm>;
  timesheetOptions: TimeSheetOptions;
  businessAreaNames: Map<number, string>;
}

const AfterregistrationTable: React.FC<AfterregistrationTableProps> = ({
  formikProps,
  timesheetOptions,
  businessAreaNames
}) => {
  const t = useTranslate("AfterregistrationPage");

  const timesheet = formikProps.values;
  const rows = timesheet.rows;
  const classes = useStyles();

  const tasks = useMemo(
    () =>
      Object.values(timesheetOptions.contracted.tasks).sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [timesheetOptions.contracted.tasks]
  );
  const serviceCategories = useMemo(
    () =>
      Object.values(timesheetOptions.contracted.servicecategories).sort(
        (a, b) => a.name.localeCompare(b.name)
      ),
    [timesheetOptions.contracted.servicecategories]
  );
  const accessories = useMemo(
    () =>
      Object.values(timesheetOptions.contracted.accessories).sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [timesheetOptions.contracted.accessories]
  );
  const materials = useMemo(
    () =>
      Object.values(timesheetOptions.contracted.materials).sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [timesheetOptions.contracted.materials]
  );

  const serviceId = formikProps.values.current_service;
  const selectedService = serviceId
    ? timesheetOptions.contracted.services[serviceId]
    : null;

  const handleAddNewRow = (push: (obj: any) => void) => {
    const prevRow = rows.length > 0 ? rows[rows.length - 1] : null;
    const defaultCategories =
      selectedService?.categories.length === 1
        ? [...selectedService.categories]
        : [];
    push({
      materials: {},
      origin: "MANUAL_ADDITION",
      accessories: [],
      servicecategory: prevRow?.servicecategory ?? defaultCategories,
      location_latitude: 0,
      location_longitude: 0,
      geo_polygons: null,
      title: `---`,
      submit_status: "DRAFT",
      details: "",
      notes: "",
      task: -1,
      service: selectedService?.id,
      start_time: prevRow?.end_time ?? "",
      end_time: ""
    });
  };

  return (
    <div className={classes.tableContainer}>
      <Toolbar className={classes.root}>
        <Typography variant="h6" id="tableTitle" className={classes.title}>
          {t("timeSheetRowsHeaderTitle")}
        </Typography>
      </Toolbar>
      <Table
        className={classes.table}
        aria-labelledby="timesheetRowTable"
        size="medium"
      >
        <EnhancedTableHead
          hideAccessories={!selectedService?.accessories.length}
          hideMaterials={!selectedService?.materials.length}
        />
        <FieldArray
          name="rows"
          render={({ push, remove }) => (
            <TableBody>
              {formikProps.values.rows.map((_row, idx) => (
                <AfterregistrationTableRow
                  key={idx}
                  formikProps={formikProps}
                  deleteRow={() => {
                    remove(idx);
                  }}
                  index={idx}
                  accessories={accessories}
                  materials={materials}
                  serviceCategories={serviceCategories}
                  tasks={tasks}
                  businessAreaNames={businessAreaNames}
                  selectedService={selectedService}
                  title={formikProps.values.title}
                />
              ))}
              <TableRow>
                <TableCell className={classes.addRowCell}>
                  <IconButton
                    data-cy="new-afterregistration-row"
                    disabled={selectedService === null}
                    color="primary"
                    onClick={() => handleAddNewRow(push)}
                  >
                    <NoteAddIcon fontSize="large" />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        />
      </Table>
    </div>
  );
};

export default AfterregistrationTable;
